<!--
todo postfach ändern, mail aus aktuellen entfernen, anzeigen in der Detail ansicht, kommentar bei schreiben
todo createticket
todo addticket
todo kommunikation server clients
todo nach getmails push
todo verschönern
todo liveserver übernahme
todo Key bei manueller Eingabe validieren (später)
todo badgets anzahl ungelesene (später)
-->

<template>
  <v-item-group style="position: relative">
  <v-app id="crmpostfach" >
    <v-app-bar
        app
        color="white"
        flat
    >
      <v-container class="py-0 fill-height">
        <v-btn-toggle
            rounded
            v-model="toggle_favoriten"
            mandatory
            dense
        >
          <v-btn text @click="setShowPostfach(0)">Alle</v-btn>
          <v-btn
            v-for="(nlfdpostfach,nlfdorder) in favoritenSort"
            :key="nlfdorder"
            text
            @click="setShowPostfach(nlfdpostfach)"
          >
            {{ favoriten[nlfdpostfach].sbezeichnung }}
          </v-btn>

          <v-btn text>
            <select @change="setShowPostfach($event.target.value)">
              <option value="">Auswahl</option>
              <option v-for="(postfach,nlfdpostfach) in postfaecher" :key="postfach.semail" :value="nlfdpostfach">{{postfach.semail}}</option>
            </select>
          </v-btn>
          <v-btn text @click="setShowPostfach('setting')">
            <v-icon >mdi mdi-clipboard-account</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-container>
    </v-app-bar>
    <v-main v-if="showPostfach==='setting'" class="grey lighten-3">
      <v-row dense style="width: 70%;margin: 0 auto">
        <v-col>
          <v-card>
            <v-card-title>Benutzer Einstellungen</v-card-title>
            <v-card-text>

              <benutzerSettings
                  id="settings"
                  :postfaecher="postfaecher"
                  :sammelpostfach="sammelpostfach"
                  :favoriten="favoriten"
                  @save-setting="saveSetting"
              >
              </benutzerSettings>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <v-main v-else class="grey lighten-3">
      <v-row dense>
        <v-col>
          <v-btn small color="primary" @click="mailWrite('NEU')"><v-icon >mdi mdi-email-outline</v-icon>Neu E-Mail Schreiben</v-btn>
          <v-btn small color="primary" v-if="showMail" @click="mailWrite('ANTWORT')"><v-icon >mdi mdi-arrow-left</v-icon>Antworten</v-btn>
          <v-btn small color="primary" v-if="showMail" @click="mailWrite('ANTWORT_ALLE')"><v-icon >mdi mdi-arrow-left-bold-circle-outline</v-icon>Allen Antworten</v-btn>
          <v-btn small color="primary" v-if="showMail" @click="mailWrite('WEITERLEITEN')"><v-icon >mdi mdi-arrow-right</v-icon>Weiterleiten</v-btn>
<!--          <v-btn small color="primary" v-if="showMail" @click="erledigt" ><v-icon >mdi mdi-check</v-icon>Erledigt</v-btn>-->
          <v-btn small color="primary" v-if="showMail" @click="ungelesen" ><v-icon >mdi mdi-email</v-icon>Ungelesen</v-btn>
          <v-btn small color="primary" v-if="showMail" @click="drucken" ><v-icon >mdi mdi-printer</v-icon>Drucken</v-btn>
          <v-btn small color="primary" v-if="showMail" @click="loeschen" ><v-icon >mdi mdi-delete</v-icon>Löschen</v-btn>
          <v-btn small color="primary" v-if="showMail" @click="spam" >SPAM</v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col  style="width: 1900px;max-width: 1900px;">
          <v-row dense>
            <v-col cols="2" class="bodyCol">
              <v-card id="orderTree">
                <v-card-title></v-card-title>
                <v-card-text>
                  <ordnerTree :value="ordnerBaum" :auswahl="showordner" :edit="ordnerEdit"  draggable droppable @add-ordner="addOrdner" @rename-ordner="renameOrdner" @drop="moveOrdner" @del-ordner="deleteOrdner" @maildrop="maildrop" @set-ordner="setShowOrdner"></ordnerTree>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col  cols="5" class="bodyCol">
              <v-card>
                <v-card-title>
                  {{ postfachBez }}
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-card-title>

                <v-card-text>
                  <v-data-table id="postfachMailList"
                      :key="renderKey"
                      :headers="headers"
                      :items="mails"
                      :search="search"
                      :fixed-header="true"
                      item-key="nlfdmail"
                      :items-per-page=-1
                      dense
                      height="600px"
                      sort-by="dtmail"
                      sort-desc
                      hide-default-footer
                      @pagination="setScrollPos(false)"
                                calculate-widths
                  >
                    <template v-slot:item="props">

                      <tr @click="setMail(props.item)" :class="mailListClass(props.item)">
                        <td ><drag  v-if="verschieben(props.item)" @dragstart="dragMail = $event.data.nlfdmail" :key="props.item.nlfdmail" :data="props.item"><v-item><v-icon>mdi mdi-cursor-move</v-icon></v-item></drag></td>
                        <td>
                          <v-icon title="geantwortet" v-if="props.item.bantwort" >mdi mdi-arrow-left</v-icon>
                          <v-icon title="Weitergeleitet" v-if="props.item.bweiterleitung">mdi mdi-arrow-right</v-icon>
                        </td>
                        <td class="silbenTrennung" v-if="props.item.ntyp == 0">{{ props.item.sabsender.replace('@','&shy;@')}}</td>
                        <td class="silbenTrennung" v-else>{{ props.item.sempfaenger.replace('@','&shy;@').replace(';','; ')}}</td>
                        <td >{{ props.item.sbetreff}}</td>
                        <td style="white-space: nowrap;" >{{ props.item.dtmail}}</td>
                        <td ><v-icon v-if="props.item.anhang > 0">mdi mdi-paperclip</v-icon></td>
                      </tr>

                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5" class="bodyCol">

              <v-card>

                <v-card-text>
                  <email v-if="showMail"
                      :mail="mail"
                      :umgebungsDaten="umgebungsDaten"
                      :meldung="emailMeldung"
                      :meldungMessage="emailMeldungMessage"
                      @write-mail="mailWrite"
                      @mail-todo="mailAufgaben"
                      @spam="spam"
                      @erledigt="erledigt"
                      @loeschen="loeschen"
                ></email>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
  <writeemail
      v-if="writeMail"
      v-bind="writeMailProps"
      :writeMailStatus="writeMailStatus"
      :writeMailMessage="writeMailMessage"
      @del-write="closeWriteMail"
      @create-mail="createMail"
  >
  </writeemail>
  </v-item-group>
</template>

<script>
import {valEmpty} from "./helper/function";

import ordnerTree from "./components/ordnerTree";
import email from "./components/email";
import writeemail from "./components/writeemail";
import benutzerSettings from "./components/benutzerSettings";
import {Drag} from "vue-easy-dnd";
import {websocketMixin} from './components/mixins/websocket.js'
const bereich = 'mail';


// var clientUser = {};


// var clientId = 0;
export default {
  components: {ordnerTree,Drag,email,benutzerSettings,writeemail},
  mixins: [websocketMixin],
  data: () => ({
    nlfdbenutzer: 0,
    favoriten: {},
    postfaecher: {},
    umgebungsDaten: {},
    search: '',
    headers: [
      { text: '',value: '' },
      { text: '',value: '' },
      { text: 'Von',value: 'sabsender' },
      { text: 'Betreff',value: 'sbetreff' },
      { text: 'Datum', value: 'dtmail' },
      { text: '',value: '' },
    ],
    showPostfach: 0,
    sammelpostfach :[],
    daten: {},
    mail:{},
    dragMail:'',
    dropOrdner:{},
    showordner:2,
    toggle_favoriten:null,
    renderKey:0,
    writeMail:false,
    writeMailStatus:null,
    writeMailMessage:null,
    writeMailProps: {},
    emailMeldung: '',
    emailMeldungMessage: '',
    scrollPos: 0,
  }),

  computed: {
    ordnerEdit() {
      if(valEmpty(this.postfaecher[this.showPostfach])) return false;
      else return parseInt(this.postfaecher[this.showPostfach].nlfdordnerprofil) > 0;
    },
    postfachBez() {
      if(!valEmpty(this.showPostfach)) {
        if(this.showPostfach === 'setting') {
          return 'Benutzereinstellungen';
        }
        return this.postfaecher[this.showPostfach].semail
      }
      return '';
    },
    selectedMail() {
      if(!valEmpty(this.mail)) {
        return this.mail.nlfdmail;
      }
      return 0;
    },

    showMail() {
      return !valEmpty(this.mail)
      // return !valEmpty(this.mail)
    },
    mails () {
      if(this.renderKey >= 0) {
        let vue = this;
        let daten = [];
        if (parseInt(this.showPostfach) === 0 && !valEmpty(vue.sammelpostfach)) {
          vue.sammelpostfach.forEach(function (nlfdpostfach) {
            if (!valEmpty(vue.daten[nlfdpostfach])) {
              Object.entries(vue.daten[nlfdpostfach]).forEach(function (mail) {
                daten.push(mail[1]);
              });
            }
          });
        } else {
          daten = this.daten[this.showPostfach]
        }
        if (!valEmpty(daten)) {
          return Object.values(daten).filter(function (mail) {
            return (mail.nlfdordner == vue.showordner || vue.showordner == 1);
          });
        }
      }
      return [];
    },
    ordnerBaum() {
      if(valEmpty(this.postfaecher[this.showPostfach])) return [];
      else return this.postfaecher[this.showPostfach].ordnertree;
    },
    favoritenSort() {
      if(!valEmpty(this.favoriten)) {
        let sort = {};
        Object.entries(this.favoriten).forEach(function ([nlfdpostfach,favorit]) {
          sort[favorit.norder] = nlfdpostfach;
        });
        return sort;
      }
      return {};
    }
  },
  methods: {
    createMail(createMail) {
      this.socketdaten.server.send(JSON.stringify({
        'action': 'createMail',
        'typ': bereich,
        'mail': createMail
      }));
    },
    closeWriteMail(){
      this.writeMailStatus = ''
      this.writeMailMessage = ''
      this.writeMail = false;
    },
    mailAufgaben(aufgabe) {
      if(aufgabe.todo === 'changeMail') {
        this.changeMail(aufgabe);
      } else if(aufgabe.todo === 'createTicket') {
        this.createTicket(aufgabe);
      } if(aufgabe.todo === 'addToTicket') {
        this.addToTicket(aufgabe);
      }
    },
    createTicket(daten) {
      this.socketdaten.server.send(JSON.stringify({
        'action': 'createTicket',
        'typ': bereich,
        'nlfdmail': daten.nlfdmail,
        'nlfdtickettyp': daten.daten.nlfdtickettyp
      }));
    },
    addToTicket(daten) {
      this.socketdaten.server.send(JSON.stringify({
        'action': 'addToTicket',
        'typ': bereich,
        'nlfdmail': daten.nlfdmail,
        'nlfdticket': daten.daten.nlfdticket
      }));
    },
    changeMail(daten) {
      this.emailMeldung = '';
      this.emailMeldungMessage = '';
      let postfachwechsel = false;
      if(!valEmpty(daten.daten.nlfdpostfach)) {
        if(daten.daten.nlfdpostfach != daten.nlfdpostfach) {
          postfachwechsel = true;
        }
        // delete this.daten[daten.nlfdpostfach][daten.nlfdmail];
      }
      this.socketdaten.server.send(JSON.stringify({
        'action': 'changeMail',
        'typ': bereich,
        'nlfdmail': daten.nlfdmail,
        'nlfdpostfach': daten.nlfdpostfach,
        'postfachwechsel':postfachwechsel,
        'daten': daten.daten
      }));
    },
    spam() {
      let nlfdpostfach = process.env.VUE_APP_MAIL_SPAM_POSTFACH;
      this.changeMail({'nlfdmail':this.selectedMail,nlfdpostfach:this.mail.nlfdpostfach,'daten':{'nlfdpostfach':nlfdpostfach,'snotiz':'Spam'}});
    },
    erledigt() {
      this.changeMail({'nlfdmail':this.selectedMail,'daten':{'nstatus':2}});
    },
    loeschen() {
      this.changeMail({'nlfdmail':this.selectedMail,'daten':{'nstatus':-1}});
    },
    ungelesen() {
      this.changeMail({'nlfdmail':this.selectedMail,'daten':{'nstatus':0}});
    },
    getWritetyp(typ) {
      let typval = 'VUE_APP_MAIL_SCHREIBEN_'+typ;
      return parseInt(process.env[typval]);
    },
    mailWrite(styp) {
      let typ = this.getWritetyp(styp);
      this.writeMailProps = {};
      if(!this.writeMail) {
        this.writeMailProps['benutzer'] = this.umgebungsDaten.benutzerDaten[this.nlfdbenutzer];
        this.writeMailProps['textbausteine'] = this.umgebungsDaten.textBausteine;
        this.writeMailProps['typ'] = typ;
        this.writeMailProps['postfaecher'] = this.postfaecher;
        this.writeMailProps['postfach'] = this.showPostfach;
        if(typ > process.env.VUE_APP_MAIL_SCHREIBEN_NEU) {
          this.writeMailProps['mail'] = this.mail;
        }
      }
      this.writeMail = !this.writeMail;
    },
    mailListClass(mail){
      let klasse = '';
      if(mail.nlfdmail === this.selectedMail) klasse += ' custom-highlight-row';
      if(mail.nstatus === 0) klasse += ' ungelesen';
      return klasse;
    },
    forceRerender() {

      let element =document.querySelector('#postfachMailList .v-data-table__wrapper');
      this.scrollPos = element.scrollTop;
      this.renderKey += 1;
    },
    setScrollPos(rest = false) {
      if(rest) {
        this.scrollPos = 0;
      }
      let scrollPos = this.scrollPos
      setTimeout(function(){
        let el = document.querySelector('#postfachMailList .v-data-table__wrapper');
        el.scrollTop = scrollPos
      }, 500);
    },
    maildrop(node) {
      let nlfdmail = this.dragMail;
      this.dragMail = '';
      let nlfdordner = node.nlfdordner;
      this.socketdaten.server.send(JSON.stringify({
        'action': 'setOrdnerMail',
        'nlfdmail': nlfdmail,
        'nlfdordner': nlfdordner
      }));
    },
    setShowPostfach(nlfdpostfach) {
      this.showPostfach = nlfdpostfach;
      this.showordner = 2;
      this.setScrollPos(true);
    },
    setShowOrdner(node) {
      this.showordner = node.nlfdordner;
    },
    setMail(item)
    {

      this.emailMeldung = '';
      this.emailMeldungMessage = '';
      this.socketdaten.server.send(JSON.stringify({
        'action': 'getMail',
        'typ': bereich,
        'nlfdkey': item.nlfdmail,
        'zugriffe': {'mail': [item.nlfdmail]}
      }));

    },
    verschieben(mail) {
      if(parseInt(mail.ntyp) === 0) {
        return true
      }
      return false;
    },
    chanceOrdner(todo,daten) {
      this.socketdaten.server.send(JSON.stringify({
        'action': 'chanceOrdner',
        'todo': todo,
        'daten': daten,
        'nlfdpostfach': this.showPostfach
      }));
    },

    addOrdner() {
      this.chanceOrdner('add',{});
    },
    renameOrdner(node) {
      this.chanceOrdner('rename',node);
    },
    moveOrdner(event) {
      let parent = this.ordnerBaum[0];
      let targetPath = event.targetPath;
      for (let i = 1; i < (targetPath.length-1); i++) {
        parent = parent['children'][targetPath[i]];
      }
      let node = event.dragNode;
      node.nlfdparent = parent.nlfdordner;
      this.chanceOrdner('move',node);
    },
    deleteOrdner(node) {
      this.chanceOrdner('delete',node);
    },
    saveSetting(settings)
    {
      this.socketdaten.server.send(JSON.stringify({
        'action': 'saveSetting',
        'settings': settings,
      }));

    },
    startFavorit()
    {
      this.showPostfach = Object.values(this.favoritenSort)[0];
    },
    drucken() {
      this.$htmlToPaper('mailView');
    },
    connect() {
      this.socketdaten.server = new WebSocket('wss://portal.pharao24.de/wss/mail');
      this.socketdaten.server.onopen = () => {
        this.socketdaten.server.send(
            JSON.stringify({'action':'benutzerAnmeldung','cookie':document.cookie,'bereich':'mail'})
        );
        this.status = "connected";
        this.socketdaten.status = true;
        this.socketdaten.bereich = bereich;
        // this.logs.push({ event: "Connected to", data: 'wss://portal.pharao24.de/wss/mail'});
        this.socketdaten.server.send(JSON.stringify({'action':'setDaten','bereich':bereich}));


        this.socketdaten.server.onmessage = ({data}) => {
          let daten = JSON.parse(data);
          if(daten.action === 'setDaten') {
            this.postfaecher = Object.assign(daten.postfaecher,this.postfaecher);
            this.favoriten = daten.favoriten;
            this.toggle_favoriten = 3;
            this.startFavorit();
            this.sammelpostfach = daten.sammelpostfach;
            this.umgebungsDaten = daten.umgebungsDaten;
            this.socketdaten.zugriff = daten.zugriff;
          } else if(daten.action === 'setMails') {
            this.daten[daten.nlfdpostfach] = daten.mails;
          }  else if(daten.action === 'saveSetting') {
            this.favoriten = daten.favoriten;
            this.sammelpostfach = daten.sammelpostfach;
          } else if(daten.action === 'changePostfachMail') {
            this.daten[daten.nlfdpostfach][daten.mail.nlfdmail] = daten.mail;
            this.daten[daten.nlfdpostfach][daten.mail.nlfdmail].nstatus = daten.mail.nstatus
            // this.forceRerender();
            if(daten.nlfdpostfach == this.showPostfach) {
              this.forceRerender();
            }
          } else if(daten.action === 'setZugriff') {
            this.socketdaten.zugriff = daten.zugriff;
          } else if(daten.action === 'clientuser') {
            // clientUser = daten.clientuser;
          } else if(daten.action === 'angemeldeterUser') {
            this.nlfdbenutzer = daten.nlfdbenutzer;
          } else if(daten.action === 'getMail') {
            this.mail = daten.mail;
            if(this.mail.ntyp == 2) {
              this.mailWrite('ENTWURF_UEBERNAHME')
            }
          } else if(daten.action === 'deletePostfachMail') {
            delete this.daten[daten.nlfdpostfach][daten.nlfdmail];
            if(daten.nlfdpostfach == this.showPostfach) {
              this.forceRerender();
            }
            // this.forceRerender();
          } else if(daten.action === 'mailChanceInfo') {
            this.emailMeldung = parseInt(daten.status);
            this.emailMeldungMessage = daten.message;
          } else if(daten.action === 'createMail') {
            this.writeMailStatus = daten.angelegt;
            this.writeMailMessage = daten.message;
            if(!valEmpty(daten.mail)) {
              this.daten[daten.mail.nlfdpostfach][daten.mail.nlfdmail] = daten.mail;
            }
          }  else if(daten.action === 'addMails') {
            if(!valEmpty(daten.mails)) {
              let vue = this;
              let render = false;
              daten.mails.forEach(function(mail) {
                vue.daten[mail.nlfdpostfach][mail.nlfdmail] = mail;
                if(mail.nlfdpostfach == vue.showPostfach) {
                  render = true;
                }
              });
              if(render) {
                this.forceRerender();
              }
            }
          } else if(daten.action === 'chanceOrdner') {
            this.postfaecher[daten.nlfdpostfach] = daten.postfach;
          }  else if(daten.action === 'delTicket') {
            delete this.daten[daten.ticket.nlfdticketart][daten.ticket.nlfdticket];
          } else if(daten.action === 'onOpen') {
            // clientId = daten.resourceid;
          } else if(daten.action === 'onClose') {
            this.socketdaten.zugriff = daten.zugriff;
            // clientUser = daten.clientuser;
          } else {
            this.logs.push({ event: "Recieved message", data });
          }
        };
      };
    },
    disconnect() {
      this.socketdaten.server.close();
      this.status = "disconnected";
      this.logs = [];
    },
    /*sendMessage(e) {
      this.socketdaten.server.send(JSON.stringify({'action':'vuetest','message':this.message}));
      this.logs.push({ event: "Sent message", data: this.message });
      this.message = "";
    }*/
  },
  created() {
    this.connect();
  }
}
</script>

<style>

.v-btn {
  text-transform: none;
  margin: 0 2px;
}
iframe {
  width: 100%;
  height: 450px;
}
tr.custom-highlight-row {
  background: lightblue;
}
tr.ungelesen {
  font-weight: bold;
}

#writeMail {
  position: absolute;
  z-index: 9;
  /*background-color: #f1f1f1;*/
  /*border: 1px solid #d3d3d3;*/
  /*text-align: center;*/
  /*box-shadow: 0 0 5px #666;*/
  /*-moz-box-shadow: 0 0 5px #666;*/
  /*-webkit-box-shadow: 0 0 5px #666;*/
}
#writeMail .card{
  /*box-shadow: 0 0 5px #666;*/
  /*-moz-box-shadow: 0 0 5px #666;*/
  /*-webkit-box-shadow: 0 0 5px #666;*/
}

#crmpostfach .v-data-table td,
#crmpostfach .v-data-table th{
  padding: 0 8px 0 0;
}
.mdi-cursor-move {
  cursor: grab;
}
#writeMailHeader {
  padding: 4px 30px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}
#orderTree .v-card__text {
  font-size: 1.1rem;
  line-height: 1.5rem;
}
.v-input {
  font-size: 1.1em;
}
#crmpostfach {
  font-size: 14px;
  height: 830px;
  overflow: scroll;
}
.silbenTrennung {

  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
@media print {
  body {
    background: red;
  }
}
/*.bodyCol tbody{*/
/*  max-height: 700px;*/
/*  overflow: auto;*/
/*}*/
</style>
