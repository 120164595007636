<!-- Demo6CustomTreeView.vue -->
<script>
import 'he-tree-vue/dist/he-tree-vue.css'
import {Tree, Fold, Check, Draggable} from 'he-tree-vue'
import { Drop } from "vue-easy-dnd";

// import * as hp from 'helper-js'

export default {
  extends: Tree,
  components: {Drop},
  mixins: [Fold, Check, Draggable],
  props: {
    triggerClass: {default: 'drag-trigger'},
    // 让树默认不可拖拽. prevent drag by default.
    draggable: {type: [Boolean, Function], default: false},
    droppable: {type: [Boolean, Function], default: false},
    edit: {default: false}
  },
  data() {
    return {
      treeClass: 'my-tree-view1',
      treeOpenCloseIcon:'fold-btn v-icon notranslate mdi theme--light',
      treeOpen: true,
      bearbeiten: false,
      auswahl: {}
    }
  },
  computed: {

  },
  methods: {
    overrideSlotDefault({node}) {
      if((node.nbearbeiten == 0 || !this.bearbeiten)&& node.nmaildrop == 0 ) {
        return <div class="node-content" onclick={() => this.setOrdner(node)}>
          {node.sname}
        </div>
      } else if ((node.nbearbeiten == 0 ||  !this.bearbeiten)  && node.nmaildrop == 1) {
        return <drop class="node-content" ondrop={() => this.maildrop(node)}  onclick={() => this.setOrdner(node)}>
          {node.sname}
        </drop>
      }
      /*<button class="mrs fold-btn" onClick={() => tree.toggleFold(node, path)}>{node.$folded ? '+' : '-'}</button>*/
      return <div class="node-content">
        <i title="Verschieben" class="drag-trigger v-icon notranslate mdi mdi-cursor-move theme--light"></i>
      {node.sname}
        <button title="Umbenennen" class="mls v-icon notranslate mdi mdi-pencil theme--light" onclick={() => this.renameNode(node)}></button>
        <button title="Löschen" style="color:red;cursor:pointer" class="drag-trigger v-icon notranslate mdi mdi-delete theme--light"  onClick={() => this.del(node)}></button>
      </div>
    },
    blockHeader() {
      if(this.edit === false) return <div class="header"></div>
      return <div class="header">
          <div>
          <button onClick={this.add}><i title="Verschieben" class="drag-trigger v-icon notranslate mdi mdi-folder-plus theme--light"></i>Neuer Ordner</button>
      </div>
        <span>
          <button onClick={this.bearbeitenModus}>Bearbeiten</button>
        </span>
          </div>
    },
    add() {
      this.bearbeiten = true;
      this.$emit("add-ordner");
    },
    del(node) {
      this.$emit("del-ordner",node);
    },
    bearbeitenModus() {
      this.bearbeiten = !this.bearbeiten;
    },
    renameNode(node) {
      let ordnername = prompt("Ordner Name", node.sname);
      this.$set(node, 'sname', ordnername);
      this.$emit("rename-ordner",node);
    },
    maildrop(node) {
      this.$emit("maildrop",node);
    },
    setOrdner(node) {
      this.$set(this.auswahl, '$nodeBackClass', false);
      this.auswahl = node;
      this.$set(node, '$nodeBackClass', 'ausgewaehlt');
      this.$emit("set-ordner",node);
    }
  }

}
</script>

<style scoped>
.my-tree-view1 .mls{
  margin-left: 5px;
}
.my-tree-view1 .mrs{
  margin-right: 5px;
}
.my-tree-view1 .tree-node{
  padding: 0;
  border: none;
}
.my-tree-view1 .tree-node-back.ausgewaehlt{
  background: #ccc;
}
.my-tree-view1 .node-content{
  display: flex;
}
.my-tree-view1 .node-content .fold-btn{
  display: flex;
  justify-content: center;
  width: 22px;
  border-radius: 100%;
  border: none;
  background: #fcf1a8;
}
.my-tree-view1 .tree-node-back:hover{
  background: #f5f5f5;
}
.my-tree-view1 .header{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.my-tree-view1 .footer{
  border-top: 1px solid #ccc;
  margin-top: 10px;
}
.my-tree-view1 .node-content .fold-btn {
  border-radius: 0;
  border: 1px solid black;
  background: transparent;
  height: 14px;
  width: 14px;
  line-height: 14px;
  margin: 4px 4px;
}
button:focus {
  outline: none;
}
.noneBearbeiten .noneBearbeiten {
  /*display: none !important;*/
}
.drop-allowed {
  background-color: rgba(0, 255, 0, 0.2);
}
</style>