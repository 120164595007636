export function valEmpty(value,json = false) {
  if(value === null) {
    return true;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if(typeof value === 'object') {
    if(json) {
      value = JSON.parse(JSON.stringify(value));
    }
    return JSON.stringify(value) === JSON.stringify({});
    // return Object.getOwnPropertyNames(value).length === 0;
  } else{
    switch (value) {
      case "":
      case undefined:
      case 0:
      case "0":
      case null:
      case false:
      case typeof (e) == "undefined":
        return true;
      default:
        return false;
    }
  }
}
export function valEmpty2(value,json = false) {
  if(value === null) {
    return true;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if(typeof value === 'object') {
    if(json) {
      value = JSON.parse(JSON.stringify(value));
    }
    return JSON.stringify(value) === JSON.stringify({});
  } else{
    switch (value) {
      case "":
      case undefined:
      case 0:
      case "0":
      case null:
      case false:
      case typeof (e) == "undefined":
        return true;
      default:
        return false;
    }
  }
}

export function dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "Header")) {
    // if present, the header is where you move the DIV from:
    document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

export function pairObjToArray(obj)
{
  let array = [];
  Object.entries(obj).forEach(function ([nlfdkey,stext]) {
    array.push({'value':nlfdkey,'text':stext}) ;
  });
  return array;
}