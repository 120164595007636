<template>
  <div id="writeMail" style="top: 50px;left: 100px;width: 900px;height: 1100px">
    <v-app style="height: 100%;background: transparent">
      <v-card elevation="3" outlined>
        <v-card-title id="writeMailHeader">
          E-Mail schreiben</v-card-title>
        <v-card-text>
          <v-alert type="success" color="green" v-if="writeMailStatus" >
            Erfolgreich
          </v-alert>
          <v-alert type="warning" v-else-if="writeMailMessage" >
            {{ writeMailMessage }}
          </v-alert>
          <v-container >
            <v-btn small class="blue" dark text @click="saveMail(true)" ><v-icon >mdi mdi-send</v-icon>Senden</v-btn>
            <v-btn small class="blue" dark text @click="saveMail(false)" ><v-icon >mdi mdi-content-save</v-icon>Entwurf</v-btn>
            <v-btn small class="blue" dark text @click="loeschen()"><v-icon >mdi mdi-delete</v-icon>Löschen</v-btn>
          </v-container>
          <form>
            <v-select  class="py-1"
                v-model="nlfdpostfach"
                :items="postfachauswahl"
                item-text="semail"
                item-value="nlfdpostfach"
                label="Postfach"
                @change="postfachWechsel"
            ></v-select>
              <v-combobox  class="py-1"
                  v-model="empfaenger"
                  :items="mailadressen"
                  :search-input.sync="searchAN"
                  hide-selected
                  label="An"
                  multiple
                  persistent-hint
                  small-chips
                  @change="toggleMenu('empfaenger')"
                  :rules="mailRules"
                  clearable
                  :menu-props="menuSetting"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Zum hinzufügen von "<strong>{{ searchAN }}</strong>". <kbd>enter</kbd> Drücken
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>

              <v-combobox  class="py-1"
                  v-model="cc"
                  :items="mailadressen"
                  :search-input.sync="searchCC"
                  hide-selected
                  label="CC"
                  multiple
                  persistent-hint
                  small-chips
                  @change="toggleMenu('cc')"
                  :rules="mailRules"
                  clearable
                  :menu-props="menuSetting"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Zum hinzufügen von "<strong>{{ searchCC }}</strong>". <kbd>enter</kbd> Drücken
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>

              <v-combobox  class="py-1"
                  v-model="bcc"
                  :items="mailadressen"
                  :search-input.sync="searchBCC"
                  hide-selected
                  label="BCC"
                  multiple
                  persistent-hint
                  small-chips
                  @change="toggleMenu('bcc')"
                  :rules="mailRules"
                  clearable
                  :menu-props="menuSetting"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Zum hinzufügen von "<strong>{{ searchBCC }}</strong>". <kbd>enter</kbd> Drücken
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            <v-text-field  class="py-0"
                v-model="betreff"
                label="Betreff"
                required
            ></v-text-field>
            <v-row class="py-1">
              <v-col cols="7" class="py-0">

                <v-select  class="py-0" dense
                    v-model="textbaustein"
                    :items="textbausteinauswahl"
                    item-text="sname"
                    item-value="nlfdtextbaustein"
                    label="Textbaustein"
                ></v-select>
              </v-col>
              <v-col cols="5" class="py-0">

                <v-btn dense small :disabled="textbaustein === ''" @click="addTextbaustein">Textbaustein Einfügen</v-btn>
              </v-col>
            </v-row>
            <ckeditor :editor="editor" v-model="body" @input="editbody=true"></ckeditor>
            <v-container  class="py-2">
              <v-row no-gutters justify="center" align="center">
                <v-col cols="8">
                  <v-file-input
                      v-model="uploadfiles"
                      color="blue"
                      counter
                      label="Daute Upload"
                      multiple
                      placeholder="Dateien auswählen"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :show-size="1000"
                      dense
                  >
                  </v-file-input>
                </v-col>

                <v-col cols="4" class="pl-2">
                  <v-btn color="blue" dark small @click="upload">
                    Upload
                    <v-icon right dark>mdi-cloud-upload</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container  class="py-2">
              <v-chip
                  v-for="(datei,key) in anhang" :key="datei.sname"
                  class="ma-2"
                  v-model="datei.isOpen"
                  close
                  @click:close="delAnhang(key)"
              >
                {{ datei.sname }}
              </v-chip>
            </v-container>
          </form>
        </v-card-text>
      </v-card>


    </v-app>
  </div>
</template>

<script>

// import DialogDrag from 'vue-dialog-drag'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import http from "../http-portal";
import { dragElement,valEmpty} from "../helper/function";
import {mailadressen} from "../helper/mailadressen";
import {getSignatur} from "../helper/signatur";
export default {
  components: {ckeditor: CKEditor.component},

name: "writeemail",
  data() {
    return {
      uploadfiles: [],
      empfaenger: [],
      anhang: [],
      cc: [],
      bcc: [],
      mailadressen: mailadressen,
      betreff: '',
      body: '',
      textbaustein: '',
      editbody: false,
      editor: ClassicEditor,
      nlfdpostfach: '',
      options: {
  'buttonPin':false,
  'width':800
      },
      searchAN: null,
      searchCC: null,
      searchBCC: null,
      bodyHeader: '<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/><title></title></head><body>',
      bodyFooter: '</body></html>',
      mailRules: [
        v =>   {
          let check = true;
          if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (!(/.+@.+/.test(v[i]))) {
                check = false;
              }
            }
          }
          return check;
        }
      ],
      // menuSetting:{"auto":true}
      menuSetting:{"closeOnClick": true, "closeOnContentClick": true, "disableKeys": true, "openOnClick": false, "maxHeight": 600}
    }
  },
  props: {
    postfaecher: {
      type: Object, default() {
        return {}
      }
    },
    mail: {
      type: Object, default() {
        return {}
      }
    },
    textbausteine: {
      type: Object, default() {
        return {}
      }
    },
    typ: {default: 0},
    postfach: {default: 0},
    writeMailStatus: {default: null},
    writeMailMessage: {default: null},
    benutzer: {type: Object,default () {
        return {}
      }},
  },
  computed: {
    postfachauswahl() {
      return Object.values(this.postfaecher)
    },
    textbausteinauswahl() {
      return Object.values(this.textbausteine)
    },
  },
  methods: {
    postfachWechsel() {
      if(!this.editbody && this.typ === 1) {
        this.body = '<p></p>' + getSignatur(this.postfaecher[this.nlfdpostfach].nlfdportal, this.benutzer.sname);
      }
    },
    delAnhang(key) {
      this.anhang.splice(key, 1);
    },
    loeschen() {
      this.$emit("del-write");
      this.$destroy()
    },
    saveMail(senden) {
      let save = true;
      if(!valEmpty(this.uploadfiles)) {
        save = confirm('Es gibt noch nicht hochgeladene Anhänge, trotzdem fortfahren');
      }
      if(save) {
        let createmail = {};
        createmail.ntyp = 2;
        if(senden) {
          createmail.ntyp = 1;
        }
        createmail.nlfdpostfach = this.nlfdpostfach;
        createmail.sbetreff = this.betreff;
        createmail.sbodyhtml = this.bodyHeader+this.body+this.bodyFooter;
        createmail.empfaenger = this.empfaenger;
        createmail.cc = this.cc;
        createmail.bcc = this.bcc;
        createmail.anhang = this.anhang;
        if(this.typ == process.env.VUE_APP_MAIL_SCHREIBEN_ENTWURF_UEBERNAHME) {
          createmail.nlfdmail = this.mail.nlfdmail;
        } else if(this.typ > 0 && !valEmpty(this.mail)) {
          createmail.nlfdursprung = this.mail.nlfdmail;
          createmail.sendetyp = 0;
          if(this.typ == process.env.VUE_APP_MAIL_SCHREIBEN_WEITERLEITEN) {
            createmail.sendetyp = 1;
          }
        }
        this.$emit("create-mail",createmail);
      }
    },
    upload()
    {
      let vue = this;
      let formData = new FormData();
      vue.uploadfiles.forEach(function (file) {
        formData.append("file[]", file);
      });
      http.post("ajax/anhangUpload.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }})
      .then(response => {
        vue.anhang = vue.anhang.concat(response.data);
        vue.uploadfiles = [];
      });
    },
    toggleMenu(auswahl) {
      if(auswahl === 'empfaenger') {
        this.searchAN = '';
        if (this.empfaenger[this.empfaenger.length - 1] === "") {
          this.empfaenger.pop();
        }
      } else if(auswahl === 'cc') {
        this.searchCC = '';
        if (this.cc[this.cc.length - 1] === "") {
          this.cc.pop();
        }
      } else if(auswahl === 'bcc') {
        this.searchBCC = '';
        if (this.bcc[this.bcc.length - 1] === "") {
          this.bcc.pop();
        }
      }
    },
    setBody(orgBodyHtml)
    {
      let bodyContent = '<p></p>' + getSignatur(this.postfaecher[this.nlfdpostfach].nlfdportal, this.benutzer.sname);

      this.body = bodyContent+orgBodyHtml;
    },
    buildBodyContent(orgEmpfaenger,orgCC) {
      let orgHead = '';
      orgHead += '<strong>Von:</strong>'+this.mail.sabsender+'<br>';
      orgHead += '<strong>Gesendet:</strong>'+this.mail.dtmail+'<br>';
      orgHead += '<strong>An:</strong>'+orgEmpfaenger.join('; ')+'<br>';
      if(!valEmpty(orgCC)) {
        orgHead += '<strong>CC:</strong>'+orgCC.join('; ')+'<br>';
      }
      orgHead += '<strong>Betreff:</strong>'+this.mail.sbetreff+'<br>';
      let mailbody = this.mail.sbodyhtml;
      if(valEmpty(mailbody)) {
        mailbody = this.mail.sbodytext;
      }
      return '<p>--------------------------------------------------------------------</p><p>' + orgHead + '</p>'+ mailbody;
    },
    addTextbaustein() {
      this.body = this.textbausteine[this.textbaustein].stext + this.body;
      this.textbaustein = '';
    },
  },
  created()
  {
    let vue = this;
    this.nlfdpostfach = parseInt(this.postfach);
    if(this.typ > 1 && !valEmpty(this.mail)) {
      let orgEmpfaenger = [];
      let orgCC = [];
      let orgBCC = [];
      Object.entries(this.mail.empfaenger).forEach(function (ar_empfaenger) {
        let empfaenger = ar_empfaenger[1];
        if(empfaenger.ntyp === 0) {
          orgEmpfaenger.push(empfaenger.semail);
        } else if(empfaenger.ntyp === 1) {
          orgCC.push(empfaenger.semail);
        } else if(empfaenger.ntyp === 2) {
          orgBCC.push(empfaenger.semail);
        }
      });
      let orgBodyHtml = this.buildBodyContent(orgEmpfaenger,orgCC);
      this.setBody(orgBodyHtml);
      this.betreff = this.mail.sbetreff;
      this.nlfdpostfach = parseInt(this.mail.nlfdpostfach);
      if(this.typ == process.env.VUE_APP_MAIL_SCHREIBEN_WEITERLEITEN ) {
        if(!valEmpty(this.mail.anhang)) {
          Object.entries(this.mail.anhang).forEach(function (anhang) {
            vue.anhang.push(anhang[1]);
          });
        }
      } else if(this.typ == process.env.VUE_APP_MAIL_SCHREIBEN_ANTWORT) {
        this.empfaenger.push(this.mail.sabsender);
      } else if(this.typ == process.env.VUE_APP_MAIL_SCHREIBEN_ANTWORT_ALLE) {
        vue.empfaenger = orgEmpfaenger;
        vue.empfaenger.push(this.mail.sabsender);
        vue.empfaenger = vue.empfaenger.filter(function(e) { return e !== vue.postfaecher[vue.nlfdpostfach].semail });
        vue.cc = orgCC;
      } else if(this.typ == process.env.VUE_APP_MAIL_SCHREIBEN_ENTWURF_UEBERNAHME) {
        vue.empfaenger = orgEmpfaenger;
        vue.cc = orgCC;
        vue.bcc = orgBCC;
        if(!valEmpty(this.mail.anhang)) {
          Object.entries(this.mail.anhang).forEach(function (anhang) {
            vue.anhang.push(anhang[1]);
          });
        }
        this.body = this.mail.sbodyhtml;
      }

    } else {
      if(this.nlfdpostfach > 0) {
        this.setBody();
      }
    }
  },
  mounted() {
    dragElement(document.getElementById("writeMail"));
  },
  watch: {
    writeMailStatus: function () {
      if(this.writeMailStatus){
        let vue = this;
        setTimeout(function(){ vue.loeschen() }, 1000);
      }
    },
  }
}
</script>

<style scoped>
.v-text-field {
  margin-top: 0;
}
</style>
<style>
.v-text-field__details {display: none}
.ck-content { height:300px; }
</style>