function valEmpty(value,json = false) {
  if(value === null) {
    return true;
  }
  if(typeof value == 'object') {
    if(json) {
      value = JSON.parse(JSON.stringify(value));
    }
    return Object.getOwnPropertyNames(value).length === 0;
  } else {
    switch (value) {
      case "":
      case undefined:
      case 0:
      case "0":
      case null:
      case false:
      case typeof (e) == "undefined":
        return true;
      default:
        return false;
    }
  }
}
export const websocketMixin = {
  props: {
    socketdaten: {type: Object,
      default () {
        return {'status':false,'zugriff':{},'bereich':'',server:{}}
      }},
  },
  computed: {
    socketStatus () {
      if(this.socketdaten.status) {
        return 'connected';
      }
      return 'disconnected';
    }
  },
  methods: {

    imZugriff(nlfdkey, bereich = "") {
      if(this.socketdaten.status === true) {
        if ("" == bereich) bereich = this.socketdaten.bereich;
        let client = this.socketdaten.zugriff[bereich][nlfdkey];

        if (!valEmpty(client)) {
          return client;
        }
      }
      return 0;
    },
    userId(client) {
      return 'clientBenutzer'+client;
      // return getClientBenutzer(client,false);
    },
  },
};
