<template>
  <div id="settings">
    <v-row dense v-if="aenderungen">
      <v-col>
      <v-alert type="info">
        Es gibt nicht gespeicherte &Auml;nderungen
      </v-alert>
      <v-btn color="primary" @click="speichern">Speichern</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>Postfach</v-col>
      <v-col>Sammelpostfach</v-col>
      <v-col>Favoriten</v-col>
      <v-col>Bezeichnung</v-col>
      <v-col>Sortierung
        <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="primary"
              v-bind="attrs"
              v-on="on"
          >
            mdi mdi-information
          </v-icon>
        </template>
        <span>Zahl darf nur einmal Vorkommen</span>
      </v-tooltip></v-col>
    </v-row>
    <v-row dense v-for="(postfach,nlfdpostfach) in postfaecher" :key="postfach.semail">
      <v-col>{{postfach.semail}}</v-col>
      <v-col><v-switch  dense v-model="sammelpostfachCom" :value="nlfdpostfach" ></v-switch></v-col>
      <v-col><v-switch dense v-model="favoritauswahl" :value="nlfdpostfach" ></v-switch></v-col>
      <v-col><v-text-field
          v-if="showFavoritenInputs(nlfdpostfach)"
          label="Solo"
          v-model="getFavorit(nlfdpostfach).sbezeichnung "
          solo
          dense
          hide-details
      ></v-text-field></v-col>
      <v-col>
        <v-text-field
          v-if="showFavoritenInputs(nlfdpostfach)"
          label="Solo"
          v-model="getFavorit(nlfdpostfach).norder"
          solo
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {valEmpty} from "../helper/function";
export default {
name: "benutzerSettings",
  data() {
    return {
      favoritstart: [],
      favoritauswahl: [],
      sammelpostfachCom: [],
      favoritenCom:{},
      // postfaecherCom:{},
    }
  },
  props: {
    postfaecher: {
      type: Object, default() {
        return {}
      }
    },
    favoriten: {
      type: Object, default() {
        return {}
      }
    },
    sammelpostfach: {
      type: Array, default() {
        return []
      }
    },
  },
  computed: {
    aenderungen() {
      if(JSON.stringify(this.favoritstart) !== JSON.stringify(this.favoritauswahl)) return true;
      if(JSON.stringify(this.sammelpostfach) !== JSON.stringify(this.sammelpostfachCom)) return true;
      if(JSON.stringify(this.favoriten) !== JSON.stringify(this.favoritenCom)) return true;
      return false;
    }
  },
  methods: {
    speichern() {
      let vue = this;
      let favoriten = {};
      let nlfdpostfach;
      Object.entries(this.favoritauswahl).forEach(function (favoritentmp) {
        nlfdpostfach = favoritentmp[1];
        favoriten[nlfdpostfach] = vue.favoritenCom[nlfdpostfach];
      });
      this.$emit("save-setting", {'sammelpostfach':vue.sammelpostfachCom,'favoriten':favoriten});
    },
    showFavoritenInputs(nlfdpostfach)
    {
      return (this.favoritauswahl.indexOf(nlfdpostfach) >= 0);
    },
    getFavorit(nlfdpostfach) {
      if(valEmpty(this.favoritenCom[nlfdpostfach])) {
        this.favoritenCom[nlfdpostfach] = {'nlfdpostfach':nlfdpostfach,'sbezeichnung':this.postfaecher[nlfdpostfach].semail,'norder':20}
      }
      return this.favoritenCom[nlfdpostfach];
    }
  },
  created() {
    let vue = this;
    Object.entries(this.favoriten).forEach(function (postfach) {
      vue.favoritauswahl.push(postfach[1].nlfdpostfach.toString());
    });
    this.favoritstart = this.favoritauswahl.slice();
    this.sammelpostfachCom = this.sammelpostfach.slice();
    this.favoritenCom = JSON.parse(JSON.stringify(this.favoriten));
  }
}
</script>

<style scoped>
#settings {
  width: 1000px;
}
.v-input--selection-controls {
  margin-top: 0;
}
</style>