import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueHtmlToPaper from 'vue-html-to-paper';


Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.

  // TODO: Perform any custom logic or log to server
  console.log('Err: '+err);
  console.log('vm: '+vm);
  console.log('info: '+info);
  if(String(err).includes("CKEditor")) {
    alert("Fehler im Editor, eingegebener Text wurde wahrscheinlich nicht übernommen");
  }
};

Vue.use(VueHtmlToPaper,{
  "name": "_blank",
  "specs": [
    "fullscreen=no",
    "titlebar=no",
    "scrollbars=no"
  ],
  "styles": [
    "https://portal.pharao24.de/css/drucken.css"
  ]
});

Vue.config.productionTip = false

new Vue({
  vuetify,VueHtmlToPaper,
  render: h => h(App)
}).$mount('#app')
