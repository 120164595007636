export function getSignatur(nlfdportal = 1,name = '') {
  let firma = 'Pharao24.de';
  let domain = 'pharao24';
  let tel = '+49 (0) 29 73 - 979 400 10'
  if(nlfdportal === 2) {
    firma = 'Wohnen.de';
    domain = 'wohnen';
    tel = '+49 (0) 29 73 - 979 400 40'
  }
  if('' === name) {
    name = 'Ihr Team von ' + firma;
  }
  return 'Mit freundlichen Gr&uuml;&szlig;en<br />\n' +
    '<br />\n' +
    name + '\n' +
    '<br />\n' +
    '+ + + + + + + + + + + + + + + + + + + + + + + +<br />\n' +
    '<br />\n' +
    'D&amp;S Trading GbR<br />\n' +
    'Handelsgesellschaft<br />\n' +
    '<br />\n' +
    firma + ' M&ouml;bel Onlineshop<br />\n' +
    'www.' + domain + '.de - Ein K&ouml;nigreich f&uuml;r M&ouml;bel!<br />\n' +
    '<br />\n' +
    'eMail: service@' + domain + '.de<br />\n' +
    'Tel. ' + tel + '<br />\n' +
    'Fax +0 (0) 29 73 - 97 940 210<br />\n' +
    '<br />\n' +
    'Vertreten durch die gesch&auml;ftsf&uuml;hrenden Gesellschafter: Christoph Donner &amp; Matthias Schaub<br />\n' +
    'Steuer-Nr.: 334/5820/0068 - UStID-Nr.: DE814902957<br />\n' +
    '<br />\n' +
    'Informationen zur Erhebung und Verarbeitung Ihrer personenbezogenen Daten entnehmen Sie bitte unseren Datenschutzhinweisen (http://www.' + domain + '.de/datenschutz)<br />';
}