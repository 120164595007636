import { render, staticRenderFns } from "./benutzerSettings.vue?vue&type=template&id=0b41070e&scoped=true&"
import script from "./benutzerSettings.vue?vue&type=script&lang=js&"
export * from "./benutzerSettings.vue?vue&type=script&lang=js&"
import style0 from "./benutzerSettings.vue?vue&type=style&index=0&id=0b41070e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b41070e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VCol,VIcon,VRow,VSwitch,VTextField,VTooltip})
