<template>
  <v-container>

    <v-container class="py-0 fill-height" style="display: none">
      <v-btn x-small text @click="ungelesen()" title="ungelesen" ><v-icon>mdi mdi-email</v-icon></v-btn>
      <v-btn x-small text @click="drucken()" title="drucken" ><v-icon>mdi mdi-printer</v-icon></v-btn>
      <v-btn x-small text @click="mailWrite('ANTWORT')" title="Antworten" ><v-icon >mdi mdi-arrow-left</v-icon></v-btn>
      <v-btn x-small text @click="mailWrite('ANTWORT_ALLE')" title="Allen Antworten" ><v-icon >mdi mdi-arrow-left-bold-circle-outline</v-icon></v-btn>
      <v-btn x-small text @click="mailWrite('WEITERLEITEN')" title="Weiterleiten" ><v-icon >mdi mdi-arrow-right</v-icon></v-btn>
      <v-btn x-small text @click="$emit('spam')" title=SPAM >SPAM</v-btn>
      <v-btn x-small text @click="$emit('erledigt')" title="Erledigt" ><v-icon >mdi mdi-check</v-icon></v-btn>
      <v-btn x-small text @click="$emit('loeschen')" title="Löschen" ><v-icon >mdi mdi-delete</v-icon></v-btn>
    </v-container>
    <v-alert type="success" color="green" v-if="meldung === 1" >
      {{ meldungMessage }}
    </v-alert>
    <v-alert type="warning" v-else-if="meldung === 2" >
      {{ meldungMessage }}
    </v-alert>
    <v-container class="py-1">
        <v-tabs v-model="changetab" hide-slider background-color="grey lighten-2" height="30">
          <v-tab dense href="#" style="display: none"></v-tab>
          <v-tab dense href="#key">Key ändern</v-tab>
          <v-tab dense href="#postfach">Postfach ändern</v-tab>
          <v-tab dense href="#ticket">Ticket ändern</v-tab>
        </v-tabs>
        <v-tabs-items v-model="changetab">
          <v-tab-item value="key">
            <v-row>
              <v-col>
                <v-select
                    v-model="keyeingabe"
                    :items="mail.keyauswahl"
                    :item-value="item => item"
                    :item-text="item => umgebungsDaten.keyTypen[item.nlfdkeytyp] +' - '+ item.nlfdkey"
                    label="Key Ändern"
                    dense
                    outlined
                ></v-select>
                <v-btn dense color="primary" :disabled="keyeingabe === ''" @click="changeKey(false)">Keyauswahl Speichern</v-btn>
              </v-col>
              <v-col>
                <v-select
                    v-model="keytypeingabeManuel"
                    :items="pairObjToArray(umgebungsDaten.keyTypen)"
                    label="Key Typ"
                    outlined
                    dense
                ></v-select>
                <v-text-field v-model="keyeingabeManuel" label="Key" dense></v-text-field>
                <v-btn dense color="primary" :disabled="keyeingabeManuel === ''" @click="changeKey(true)">manuellen Key eintragen</v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item  value="postfach">
            <v-row>
              <v-col>
                <v-select
                    v-model="postfacheingabe"
                    :items="pairObjToArray(umgebungsDaten.postfachChange)"
                    label="In Postfach ändern"
                    outlined
                    dense
                ></v-select>

                <v-text-field v-model="postfachChangeGrund" label="Grund" dense></v-text-field>
                <v-btn dense color="primary"  :disabled="postfacheingabe === '' || postfachChangeGrund === ''" @click="changePostfach">Postfach Ändern</v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="ticket">
            <v-row>
              <v-col>
                <v-select
                    v-model="tickettypeingabe"
                    :items="ticketAnlageTypen"
                    item-value="nlfdtickettyp"
                    item-text="sbezeichnung"
                    label="Tickettyp"
                    outlined
                    dense
                ></v-select>
                <v-btn dense color="primary"  :disabled="tickettypeingabe === ''" @click="createTicket">Ticket anlegen</v-btn>
              </v-col>
              <v-col>
                <v-text-field v-model="ticketeingabe" label="Key" dense></v-text-field>
                <v-btn  dense color="primary"  :disabled="ticketeingabe === ''" @click="addToTicket">zu Ticket hinzufügen</v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
    </v-container>
    <div id="mailView">
      <div class="mailHeader">
        <table border="0" style="width: 100%">
          <tr v-if="mail.nlfdkey > 0">
            <th>{{keytyp(mail.nlfdkeytyp)}}</th>
            <td>{{mail.nlfdkey}}</td>
          </tr>
          <tr v-if="mail.ntyp == 0">
            <th>Mail</th>
            <td>{{this.mail.nlfdmail}}</td>
            <th>Erhalten</th>
            <td>{{this.mail.dtmail}}</td>
          </tr>
          <tr v-if="mail.ntyp == 1">
            <th>Mail</th>
            <td>{{this.mail.nlfdmail}}</td>
            <th>Versendet</th>
            <td>{{this.mail.dtmail}}</td>
          </tr>
          <tr v-if="mail.ntyp == 2">
            <th>Mail</th>
            <td>{{this.mail.nlfdmail}}</td>
            <th>Erstellt</th>
            <td>{{this.mail.dtinsert}}</td>
          </tr>
          <tr>
            <th>Von</th>
            <td colspan="3"><v-chip small outlined>{{this.mail.sabsender}}</v-chip></td>
          </tr>
          <tr>
            <th>An</th>
            <td colspan="3">
              <v-chip small outlined v-for="(sempfaenger,nlfdempfaenger) in empfaenger(0)" :key="nlfdempfaenger">{{sempfaenger.semail}}</v-chip>
            </td>
          </tr>
          <tr v-if="showCC">
            <th>CC</th>
            <td colspan="3">
              <v-chip small outlined v-for="(sempfaenger,nlfdempfaenger) in empfaenger(1)" :key="nlfdempfaenger">{{sempfaenger.semail}}</v-chip>
            </td>
          </tr>
          <tr v-if="showBCC">
            <th>BCC</th>
            <td colspan="3">
              <v-chip small outlined v-for="(sempfaenger,nlfdempfaenger) in empfaenger(2)" :key="nlfdempfaenger">{{sempfaenger.semail}}</v-chip>
            </td>
          </tr>
          <tr>
            <th>Betreff:</th>
            <td colspan="3">{{this.mail.sbetreff}}</td>
          </tr>
          <tr v-if="showAnhang">
            <td colspan="4">
              <a class="mail_anhang" target="_blank" v-for="(anhang,nlfdanhang) in this.mail.anhang" :key="nlfdanhang" :href="anhangUrl(anhang.spfad)">{{anhang.sname}}</a>
            </td>
          </tr>
        </table>
      </div>
      <div class="mailBody no-print">
        <iframe :src="mailIframe" frameborder="0"></iframe>
      </div>
      <div id="testdruck" class="no-screen"  >
        <span v-html="mailBody" class="print"></span>
      </div>
    </div>
  </v-container>
</template>

<script>

import {valEmpty,pairObjToArray} from "../helper/function";
// import {mailadressen} from "../helper/mailadressen";

export default {
  name: "email",
  data() {
    return {
      keyeingabe: '',
      keyeingabeManuel: '',
      postfachChangeGrund: '',
      keytypeingabeManuel: '2',
      tickettypeingabe: '',
      ticketeingabe: '',
      postfacheingabe: '',
      keytab: '',
      tickettab: '',
      changetab: '',
    }
  },
  props: {
    mail: {type: Object,default () {
        return {}
      }},
    umgebungsDaten: {type: Object,default () {
        return {}
      }},
    meldung: {default: ''},
    meldungMessage: {default: ''},
  },
  computed: {
    ticketAnlageTypen() {
      if(!valEmpty(this.mail)) {
        if(!valEmpty(this.umgebungsDaten.ticketTypen)) {
          let vue = this;
          return Object.values(this.umgebungsDaten.ticketTypen).filter(function (tickettyp) {
            return parseInt(tickettyp.nlfdmailkeytyp) === parseInt(vue.mail.nlfdkeytyp);
          });
        }
      }
      return {}
    },
    showCC() {
      return !valEmpty(this.empfaenger(1))
    },
    showBCC() {
      return !valEmpty(this.empfaenger(2))
    },
    showAnhang() {
      return !valEmpty(this.mail.anhang)
    },
    mailIframe() {
      if(valEmpty(this.mail)) {
        return '';
      } else {
        return 'https://portal.pharao24.de/mailIframe.php?nlfdmail=' + this.mail.nlfdmail;
      }
    },
    mailBody()
    {
      if(!valEmpty(this.mail.sbodyhtml)) {
        return this.mail.sbodyhtml;
      }
      return this.mail.sbodytext;
    },
    mailId()
    {
      return this.mail.nlfdmail;
    }
  },
  methods: {
    keybezeichnung(nlfdkey,nlfdkeytyp) {
      return this.umgebungsDaten.keyTypen[nlfdkeytyp]+ ' . '+nlfdkey;
    },
    pairObjToArray(objekt) {
      return pairObjToArray(objekt);
    },
    keytyp(nlfdkeytyp) {
      if(!valEmpty(this.umgebungsDaten.keyTypen[nlfdkeytyp])) {
        return this.umgebungsDaten.keyTypen[nlfdkeytyp];
      }
      return '';
    },
    anhangUrl(pfad) {
      pfad = pfad.replace(process.env.VUE_APP_PORTAL_ROOT,'');
      pfad = pfad.replace(process.env.VUE_APP_ANHANG_PFAD_REPLACE,'crm/');
      return process.env.VUE_APP_PORTAL_URL+pfad;
    },
    empfaenger(ntyp) {

      if(!valEmpty(this.mail)) {
        if(!valEmpty(this.mail.empfaenger)) {
          return Object.values(this.mail.empfaenger).filter(function (empfaenger) {
            return parseInt(empfaenger.ntyp) === ntyp;
          });
        }
      }
      return {}
    },
    changeKey(manuel) {
      let nlfdkey = this.keyeingabeManuel;
      let nlfdkeytyp = this.keytypeingabeManuel;
      if(!manuel) {
        nlfdkey = this.keyeingabe.nlfdkey;
        nlfdkeytyp = this.keyeingabe.nlfdkeytyp;
      }
      this.todo('changeMail',{'nlfdkey':nlfdkey,'nlfdkeytyp':nlfdkeytyp});
    },
    changePostfach() {
      this.todo('changeMail',{'nlfdpostfach':this.postfacheingabe,'snotiz':this.postfachChangeGrund});
    },
    ungelesen() {
      this.todo('changeMail',{'nstatus':0});
    },
    createTicket() {
      this.todo('createTicket',{'nlfdtickettyp':this.tickettypeingabe});
    },
    addToTicket() {
      this.todo('addToTicket',{'nlfdticket':this.ticketeingabe});
    },
    todo(aktion,daten) {
      this.$emit('mail-todo',{'todo':aktion,'nlfdmail':this.mail.nlfdmail,'nlfdpostfach':this.mail.nlfdpostfach,'daten':daten});
    },
    drucken() {
      this.$htmlToPaper('mailView');
    },
    mailWrite(typ) {
      this.$emit('write-mail',typ);
    },
  },
  watch: {
    mailId() {
      this.keyeingabe = '';
      this.keyeingabeManuel = '';
      this.postfachChangeGrund = '';
      this.keytypeingabeManuel = '2';
      this.tickettypeingabe = '';
      this.ticketeingabe = '';
      this.postfacheingabe = '';
      this.keytab = '';
      this.tickettab = '';
      this.changetab = '';
    }
  }
}
</script>

<style>
  .mailHeader th { width: 20%}
  .mailHeader td { width: 30%}
  .mail_anhang {
    display: inline-block;padding: 2px 5px;
  }
  .v-tab {text-transform: none !important}
  @media screen {
    .no-screen {
      display: none !important;
    }
  }
</style>